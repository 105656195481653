const CONFIG = {
    development: {
        API_BASE: 'http://localhost:33333/api/',
        WS_URL: 'ws://localhost:33333/',
        IMAGEKIT_ENDPOINT: 'https://ik.imagekit.io/nn3phmxu4z/',
    },
    staging: {
        API_BASE: 'https://simona-cherry-stg-a8ac686837a6.herokuapp.com/api/',
        WS_URL: 'wss://simona-cherry-stg-a8ac686837a6.herokuapp.com/',
        IMAGEKIT_ENDPOINT: 'https://ik.imagekit.io/nn3phmxu4z/',
    },
    production: {
        API_BASE: 'https://simona-cherry-prd-3a04bb9d86b0.herokuapp.com/api/',
        WS_URL: 'wss://simona-cherry-prd-3a04bb9d86b0.herokuapp.com/',
        IMAGEKIT_ENDPOINT: 'https://ik.imagekit.io/nn3phmxu4z/',
    },
};

export default key=>{
    const conf = CONFIG[process.env.REACT_APP_ENV]||CONFIG.development;
    return key ? conf[key] : conf;
};
