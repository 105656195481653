import React, { useCallback } from 'react';
import { Box, Button, Flex, Image, Page, Panel } from '../ui/elements.js';
import { useNavigate } from 'react-router-dom';
import theme from '../theme.js';

const Home = ()=>{
    const _navigate = useNavigate();
    const nav = useCallback(to=>()=>_navigate(to), [_navigate]);
    return <Page rtl>
      <Panel gap={theme.spacing.L} flexDirection='column'>
        <Flex gap={theme.spacing.L}>
          <Flex>
            <Image filename='simona_cherry/static/profile-pic-1-full.jpg' minWidth='100px' maxWidth='200px' objectFit='cover' rounded shadow/>
          </Flex>
          <Flex flexDirection='column' gap={theme.spacing.L}>
            <Box textSize='L'>
              האתר שלי עדיין בבנייה ¯/_(ツ)_\¯
            </Box>
            <Flex alignItems='center' flexWrap='wrap'>
              בינתיים תוכלו לתאם איתי
              <Button text='קריאה בקלפי הטארוט' onClick={nav('/tarot')}/>
            </Flex>
            <Flex alignItems='center' flexWrap='wrap'>
              <Box>או לקרוא את המדריך שלי על</Box>
              <Button text='הרגלים תודעתיים שישנו לכם את העסק והחיים' onClick={nav('/conscious-habits')}/>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='center'>
          <Image filename='simona_cherry/static/logo-header.png' maxHeight='100px' objectFit='contain'/>
        </Flex>
      </Panel>
    </Page>
};

export default Home;
