import React, {useCallback, useState} from 'react';
import {useBusyCallback, useObjectMemo} from '../util/react_hooks';
import {api} from '../lib/api.js';
import {Button, Checkbox, Flex, TextField} from '../ui/elements.js';
import theme from '../theme.js';
import {validateEmail} from '../util/util.js';

export const useMailChimp = props=>{
    const {onDone} = props;
    const [errors, setErrors] = useState();
    const validate = useCallback(props=>{
        const {email, fname, subChecked} = props;
        let _errors = {};
        if (!subChecked)
            _errors.subChecked = 'נא לאשר הרשמה';
        if (!fname)
            _errors.fname = 'נא להזין שם';
        if (!email || !validateEmail(email))
            _errors.email = 'נא להזין כתובת מייל תקינה';
        if (Object.keys(_errors).length) {
            setErrors(_errors);
            return false;
        }
        setErrors(null);
        return true;
    }, []);
    const [submit, submitting] = useBusyCallback(async ({email, fname, lname, subChecked, tags})=>{
        if (!validate({email, fname, subChecked}))
            return;
        try {
            const res = await api.addMailChimpSubscriber({email, fname, lname, tags});
            if (res.error)
                throw new Error();
            onDone?.({email, fname, lname});
        } catch (e) {
            setErrors(prev=>Object.assign(prev, {general: 'אירעה שגיאה'}));
        }
    }, [onDone]);
    return {submit, submitting, errors, validate};
};

const MailChimpForm = props=>{
    const {tags, onDone, title, className} = props;
    const {submit, submitting, errors, validate} = useMailChimp({onDone});
    const [submittedOnce, setSubmittedOnce] = useState(false);
    const inputData = useObjectMemo({
        fname: '',
        lname: '',
        email: '',
        subChecked: true,
        tags,
    });
    const onSubmit = useCallback(()=>{
        setSubmittedOnce(true);
        submit(inputData);
    }, [tags, onDone, submit]);
    const handleEnterKey = useCallback(ev=>{
        if (ev.key === 'Enter')
            onSubmit();
    });
    return <Flex className={className} flexDirection='column' justifyContent='center' alignItems='center' gap={theme.spacing.M}>
      {title && <Flex>{title}</Flex>}
      <TextField icon='fa-solid fa-user' {...errors?.fname && {error: errors.fname}} width='100%' label='שם פרטי' onKeyDown={handleEnterKey} onChange={v=>{
          inputData.fname = v;
          if (submittedOnce)
              validate(inputData);
      }}/>
      <TextField icon='fa-solid fa-envelope' {...errors?.email && {error: errors.email}} width='100%' label='מייל' onKeyDown={handleEnterKey} onChange={v=>{
          inputData.email = v;
          if (submittedOnce)
              validate(inputData);
      }}/>
      <Checkbox {...errors?.subChecked && {error: errors.subChecked}} width='100%' label='אני מאשר.ת הרשמה וקבלת תוכן שיווקי ופרסומי למייל' checked onKeyDown={handleEnterKey} onChange={v=>{
          inputData.subChecked = v;
          if (submittedOnce)
              validate(inputData);
      }}/>
      <Button loading={submitting} text='הרשמה' onClick={onSubmit}/>
      {errors?.general && <Flex fontWeight='bold' color={theme.color.err1}>{errors.general}</Flex>}
    </Flex>;
};

export default MailChimpForm;
