import { useCallback, useEffect, useState } from "react";
import { useObjectMemo } from "./react_hooks";
import { randomString } from "./util";

const DEFAULT_TITLE = `סימונה צ'רי`;

export const useTitle = ()=>{
    const titles = useObjectMemo([]);
    const setTitle = useCallback(title=>{
        const id = titles.length+randomString(128);
        titles.push({id, title});
        return ()=>{
            const idx = titles.findIndex(obj=>obj.id==id);
            if (idx != -1)
                titles.splice(idx, 1);
        };
    }, [titles]);
    useEffect(()=>{
        document.title = titles[titles.length-1]?.title || DEFAULT_TITLE;
    }, [titles]);
    return {setTitle};
};
