import React, {useState} from 'react';
import {createPageContext} from '../util/react_hooks.js';
import {Box, Flex, Image, Page, Panel, Span} from '../ui/elements.js';
import theme from '../theme.js';
import MailChimpForm from '../components/MailChimpForm.js';

const FormSentBox = ({fname})=>{
  return <Page rtl>
    <Panel>
      <Flex flexDirection='column' gap={theme.spacing.L} justifyContent='center'>
        <Box color={theme.color.ok1} textSize='L' fontWeight='bold'>
          איזה כיף שנרשמת, {fname}! המדריך תיכף אצלך במייל 🌸
        </Box>
        <Image filename='simona_cherry/static/tablet-coffee.png' maxHeight='300px' objectFit='cover' rounded shadow/>
        <Box fontStyle='italic'>
          * אם לא קיבלת אותו תוך כמה דקות, תבדקי בתיקיות ספאם וקידומי מכירות
        </Box>
      </Flex>
    </Panel>
  </Page>
};

const ConsciousHabits = ()=>{
    const [sent, setSent] = useState(false);
    const [fname, setFname] = useState(null);
    if (sent)
      return <FormSentBox fname={fname}/>;
    return <Page rtl>
      <Panel>
        <Flex flexDirection='column' gap={theme.spacing.L} justifyContent='center'>
          <Flex flexDirection='column' justifyContent='center'>
            <Box>את מרגישה שאת מתאמצת כל כך הרבה ונותנת מעצמך אבל הדברים פשוט לא קורים?</Box>
            <Box>את יודעת שמגיע לך יותר אבל את לא רואה את התוצאות בפועל?</Box>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Box>אני יודעת איך זה מרגיש מקרוב, כשאת ממש נותנת את כל כולך למשהו אבל
              {' '}<Span highlight={theme.color.orange}>לא מצליחה לממש אותו ולהגיע לתוצאה שרצית?</Span></Box>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Box><b>יש משהו משותף לכל האנשים המצליחים בעולם</b> – ניצול הזמן שלהם וההרגלים שהם מקפידים לעשות באופן קבוע.</Box>
            <Box>כשאנחנו שואפים למשהו זה מצוין, אבל בלי מערכת והתמדה, למשך כמה זמן באמת נוכל להחזיק את הרצון לממש את השאיפה?</Box>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Box>הסופר המצליח והמיליונר ג’יימס קליר אמר שאתם לא יכולים להרים
              את עצמכם לעבר השאיפות שלכם, כי אתם תמיד תיפלו בחזרה להרגלים שלכם.</Box>
            <Box>משמע, <b>כדי להצליח צריך ליצור הרגלים תודעתיים שיתמכו במטרות שלכם ❤️‍🔥</b></Box>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Image filename='simona_cherry/static/tablet-coffee.png' maxHeight='300px' objectFit='cover' rounded shadow/>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Box textSize='L'>הכנתי לך מדריך עם <Span highlight={theme.color.orange}>5 ההרגלים</Span> ששינו לי את העסק והחיים!</Box>
          </Flex>
          <Flex flexDirection='column' justifyContent='center'>
            <Box>ובגלל שאני יודעת שזה עובד, אני מבטיחה לך שאם תיישמי <u>אפילו 2 הרגלים</u> מהמדריך הזה, את תראי שינוי דרסטי לטובה בחייך 🙌</Box>
            <Box>עד עכשיו את המדריך האקסלוקסיבי הזה קיבלו <b>רק התלמידות שלי</b>, אבל בגלל שהחיים לימדו אותי שהשפע הוא אינסופי, אני רוצה לחלוק אותו עכשיו גם איתך – כדי שגם את תוכלי ליצור שינוי משמעותי בחייך.</Box>
          </Flex>
          <MailChimpForm tags={['Conscious Habits']} title={<Flex flexDirection='column' justifyContent='center'>
              <Flex justifyContent='center'><b>עכשיו לזמן מוגבל, את יכולה לקבל את המדריך הזה במתנה 🌸</b></Flex>
              <Flex justifyContent='center'>פשוט מלאי כאן את הפרטים והוא יחכה לך במייל 👇</Flex>
            </Flex>}
            onDone={({fname})=>{
                setFname(fname);
                setSent(true);
            }}/>
        </Flex>
      </Panel>
    </Page>
};

export default ConsciousHabits;
