

export const isString = value => typeof value === 'string' || value instanceof String;

export const templateToString = function (parts, ...args) {
    let result = '';
    if (!Array.isArray(parts))
        return parts;
    for (let i = 0; i < parts.length; i++) {
        result += parts[i];
        if (i < args?.length)
            result += args[i];
    }
    return result;
};

export const randomString = (length = 32) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const nl2sp = function(parts, ...args) {
    return templateToString(parts, args).replace(/\s*(\r\n|\r|\n)\s*/g, ' ');
};

export const nl2jn = function(parts, ...args) {
    return templateToString(parts, args).replace(/\s*(\r\n|\r|\n)\s*/g, '');
};

export const validateEmail = email=>email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const sleep = ms=>new Promise(resolve=>setTimeout(resolve, ms));
