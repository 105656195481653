import React from 'react';
import styled from 'styled-components';
import {Carousel} from '@angelplayer/react-carousel';
import {Page, Box, Button, Flex, Image, Panel} from '../ui/elements.js';
import theme from '../theme.js';
import config from '../config.js';

const WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=972544601846&text=%D7%94%D7%99%D7%99%20%D7%A1%D7%99%D7%9E%D7%95%D7%A0%D7%94,%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%94%D7%AA%D7%99%D7%99%D7%A2%D7%A5%20%D7%90%D7%99%D7%AA%D7%9A%20%D7%9C%D7%92%D7%91%D7%99%20%D7%A7%D7%A8%D7%99%D7%90%D7%94%20%D7%91%D7%A7%D7%9C%D7%A4%D7%99%D7%9D%20%F0%9F%8C%B8';

const WhatsAppButton = props=>{
    return <Button
      icon='fa-brands fa-whatsapp'
      text='לתיאום ייעוץ בוואצאפ'
      onClick={()=>window.open(WHATSAPP_LINK, '_blank', 'rel=noopener noreferrer')}
      {...props}
    />;
};

const CarouselContainer = styled(Flex)`
    @media (max-width: ${theme.screen.tablet}) {
        width: 400px;
    }
    @media (max-width: ${theme.screen.mobile}) {
        width: 300px;
    }
`;

const FeedbackSection = props=>{
    const slides = Array(10).fill(null)
      .map((_, i)=>({url: '#', image: `${config('IMAGEKIT_ENDPOINT')}simona_cherry/static/tarot-feedback/feedback-${i+1}.jpeg`}));
    return <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={theme.spacing.L}>
      <Box textSize='XL' fontWeight='bold'>ההודעות שלכן.ם 💜</Box>
      <CarouselContainer justifyContent='center' alignItems='center' width='600px' height='400px' style={{direction: 'ltr'}}>
        <Carousel slides={slides} speed='5000' type='contain'/>
      </CarouselContainer>
    </Flex>;
}

const PaymentForm = props=>{
    return null;
};

function TarotLanding() {
    return <Page rtl bgFilename='flowers-bg-1.png'>
      <Panel>
        <Flex flexDirection='column' gap={theme.spacing.L}>
          <Flex justifyContent='center'>
            <Image filename='simona_cherry/static/logo-header.png' width='200px' minHeight='100px' maxHeight='200px'/>
          </Flex>
          <Box textSize='S'>
            מה היית מעדיפה? לשמוע מה הולך לקרות בעתיד שלך או לדעת איך בדיוק להשיג את החיים שאת חולמת עליהם?
          </Box>
          <Box textSize='M' fontWeight='bold'>
            היי, אני סימונה צ’רי ואני כאן כדי לעזור לך למצוא את התשובות בתוכך ולברוא את המציאות שתמיד רצית בעזרת טארוט, נומרולוגיה, תקשור ופירוש חלומות.
          </Box>
          <Box textSize='S'>
            אם את תקועה בצומת דרכים בחייך, או אפילו חושבת שהשאיפות שלך בלתי אפשריות – זה הזמן שניפגש
            {' '}<em>ונחזיר את הכוח לידיים שלך!</em>
          </Box>
          <Box textSize='S'>
            עכשיו, אני רוצה לנפץ מיתוס – הרבה חושבים שטארוט זה גילוי עתידות, אבל בעצם..
            {' '}<u>זה כלי עוצמתי שמאפשר פריצת דרך.</u>
          </Box>
          <Box textSize='M'>
            <span style={{backgroundColor: theme.color.orange}}>
              בעזרת ליווי צמוד וכלים פרקטיים שאני מתאימה בדיוק בשבילך, אנחנו נבנה גשר בין
              המקום שאת נמצאת בו עכשיו למקום שבו את רוצה להיות – בקריירה, בזוגיות ובכל תחום אחר.
            </span>
          </Box>
          <Box textSize='S'>
            עד כה, עזרתי למאות א.נשים לפתור בעיות עמוקות ולמצוא את הדרך שלהם בחיים, בין אם בליווי אישי, במעגלי נשים או בסדנאות שאני מעבירה.
          </Box>
          <Flex flexWrap='wrap' alignItems='center' marginTop={theme.spacing.L}>
            <Flex padding={theme.spacing.M} flexGrow='1' justifyContent='center' alignItems='center'>
              <Image circle filename='simona_cherry/static/profile-pic-1.jpg' maxWidth='200px' maxHeight='200px'/>
            </Flex>
            <Flex padding={theme.spacing.M} flexGrow='3' alignItems='start' flexDirection='column' gap={theme.spacing.M}>
              <Flex textSize='M' fontWeight='bold'>
                מוכנה להתחיל ליצור את השינוי בחייך?
              </Flex>
              <Flex alignItems='start' flexDirection='column' gap={theme.spacing.S}>
                <Box>קריאת קלפים וייעוץ של חצי שעה בטלפון – ₪180</Box>
                <Box>ניתן להרחיב לייעוץ מעמיק של שעה בתוספת של ₪120</Box>
              </Flex>
              <WhatsAppButton/>
            </Flex>
          </Flex>
          <FeedbackSection/>
          <PaymentForm/>
          <Box textSize='S' fontStyle='italic'>
            * הטקסט מנוסח בלשון נקבה, אבל פונה לכל המינים ♥
          </Box>
        </Flex>
      </Panel>
    </Page>;
}

export default TarotLanding;
