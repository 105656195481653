import { useCallback, useState } from 'react';
import { useBusyCallback, useObjectMemo, useOnce } from '../util/react_hooks.js';
import config from '../config.js';

export const buildURL = (query, endpoint = '')=>{
    let url = config('API_BASE')+endpoint;
    if (!query || !Object.keys(query).length)
        return url;
    return url + '?' + Object.keys(query)
        .map(k => k + '=' + encodeURIComponent(query[k])).join('&');
};

export const apiCall = async ({endpoint, method = 'GET', query, payload, headers})=>{
    const url = buildURL(query, endpoint);
    const settings = {
        credentials: 'include',
        method,
        headers: {
            'Content-type': 'application/json',
            ...headers,
        },
        ...payload && { body: JSON.stringify(payload) },
    };
    try {
        const response = await fetch(url, settings);
        return await response.json();
    } catch (e) {
        throw new Error(`Failed to fetch ${url}`, e);
    }
};

export const useApiCall = ({endpoint, method = 'GET', query, payload, headers})=>{
    const [data, setData] = useState();
    const [error, setError] = useState();
    const _query = useObjectMemo(query);
    const _payload = useObjectMemo(payload);
    const _headers = useObjectMemo(headers);
    const [refresh, loading] = useBusyCallback(async ()=>{
        try {
            const _data = await apiCall({endpoint, method, _query, _payload, _headers});
            setData(_data);
            setError(null);
        } catch (e) {
            setError(e.message);
        }
    }, [endpoint, method, _query, _payload, _headers]);
    useOnce(()=>refresh(), !!endpoint);
    return {data, error, refresh, loading};
};

export const api = {
    addMailChimpSubscriber: ({email, fname, lname, tags})=>apiCall({
        endpoint: 'mc_subscriber',
        method: 'POST',
        payload: {email, fname, lname, tags},
    }),
};
