import React, {useCallback, useContext, useEffect, useState, useMemo} from 'react';
import config from '../config.js';

export const useWebsocket = ()=>{
    const [ws, setWS] = useState(null);
    const callbacks = useMemo(()=>[], []);
    useEffect(()=>void setWS(new WebSocket(config('WS_URL'))), []);
    useEffect(()=>{
        if (!ws) return;
        ws.onopen = ()=>{};
        ws.onerror = e=>console.log('WebSocket error', e);
        ws.onclose = e=>setTimeout(()=>setWS(new WebSocket(config('WS_URL'))), 3000);
        ws.onmessage = msg=>callbacks.forEach(cb=>cb(JSON.parse(msg.data)));
    }, [ws]);
    const onMessage = cb=>{
        callbacks.push(cb);
        return ()=>{
            const idx = callbacks.indexOf(cb);
            if (idx!==-1)
                callbacks.splice(idx, 1);
        };
    };
    const send = useCallback(msg=>{
        ws.send(JSON.stringify(msg));
    }, [ws]);
    const connected = useMemo(()=>ws?.readyState===1, [ws?.readyState]);
    return {onMessage, send, connected};
};
