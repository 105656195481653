import React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { useWebsocket } from './lib/websocket';
import Home from './pages/Home.js';
import ConsciousHabits from './pages/ConsciousHabits.js';
import NotFound from './pages/NotFound.js';
import Blog from './pages/blog/Blog.js';
import BlogPost from './pages/blog/BlogPost.js';
import './App.css';
import TarotLanding from './pages/TarotLanding.js';
import { useTitle } from './util/title.js';
import { useObjectMemo } from './util/react_hooks.js';
import { Box } from './ui/elements.js';

const AppContext = createContext({});
export const useAppContext = ()=>useContext(AppContext);

function App() {
  const routes = useRoutes([
      {path: '/', element: <Home/>},
      {path: '/conscious-habits', element: <ConsciousHabits/>},
      {path: '/tarot', element: <TarotLanding/>},
      {path: '/blog', element: <Blog/>},
      {path: '/blog/:postId', element: <BlogPost/>},
      {path: '*', element: <NotFound />},
  ]);
  const websocket = useWebsocket();
  const {setTitle} = useTitle();
  const ctx = useObjectMemo({
      websocket,
      setTitle,
  });
  return <Box className="App">
    <AppContext.Provider value={ctx}>
      {routes}
    </AppContext.Provider>
  </Box>;
}

export default App;
